<template>
  <v-menu
    v-bind="$attrs"
    bottom
    fixed
    v-model="model"
    min-width="250"
  >
    <template
      v-if="withActivator"
      v-slot:activator="{on, attrs }"
    >
      <v-icon
        v-on="on"
        v-bind="attrs"
      >
        mdi-dots-vertical
      </v-icon>
    </template>
    <template
      v-for="(list, index) in foldersActionsItems"
    >
      <v-list
        :key="`folders-actions-list-${index}`"
      >
        <v-list-item
          v-for="action in list.items"
          :key="`folders-action-${action.id}`"
          @click.stop.prevent="$emit('emitAction', action.id)"
        >
          <v-list-item-icon>
            <v-icon>
              {{ action.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ action.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider color="#e4e4e4" :key="`item-menu-divider-${index}`"/>
    </template>
  </v-menu>
</template>
<script>
export default {
  props: {
    withActivator: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  name: 'FolderItemMenu',
  computed: {
    foldersActionsItems() {
      return [
        {
          items: [
            {
              id: 'add',
              icon: 'mdi-folder-plus-outline',
              title: 'New folder'
            }
          ]
        },
        {
          items: [
            {
              id: 'link',
              icon: 'mdi-link',
              title: 'Get link'
            },
            {
              id: 'moveTo',
              icon: 'mdi-file-move-outline',
              title: 'Move to'
            },
            {
              id: 'starred',
              icon: 'mdi-heart-outline',
              title: 'Add to favorites'
            },
            {
              id: 'edit',
              icon: 'mdi-folder-edit-outline',
              title: 'Edit',
            }
          ]
        },
        {
          items: [
            {
              id: 'download',
              icon: 'mdi-download-outline',
              title: 'Download'
            }
          ]
        },
        {
          items: [
            {
              id: 'delete',
              icon: 'mdi-delete-outline',
              title: 'Удалить',
            }
          ]
        }
      ]
    },
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>